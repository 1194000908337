<template>
  <div style="padding: 20px">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="name" label="配置" width="180"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="handleClick(scope.row)"
            class="general_bgc general_border cfff"
            >操作</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      @closed="handleClose"
    >
      <div style="padding: 10px 20px">
        <div>
          <el-form :inline="true" :model="query" class="demo-form-inline">
            <el-col :span="8" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="请输入标签名称查询"
                  v-model="query.keyword"
                  class="input-with-select"
                  clearable
                  @clear="search"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" class="tl mr10">
              <el-form-item size="medium">
                <el-button
                  type="primary"
                  @click="search"
                  class="general_bgc general_border cfff"
                  >查询</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="3" class="tl mr10">
              <el-form-item size="medium">
                <el-popover
                  placement="bottom"
                  width="400"
                  trigger="click"
                  v-model="showAdd"
                >
                  <div style="padding: 10px">
                    <el-form
                      label-width="120px"
                      :model="formData"
                      :rules="rules"
                      ref="addForm"
                    >
                      <el-form-item prop="label" label="自定义名称">
                        <el-input
                          v-model="formData.label"
                          style="width: 60%"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                    <div class="df" style="justify-content: flex-end">
                      <el-button size="small" style="margin-right: 10px"
                        >取消</el-button
                      >
                      <el-button size="small" type="primary" @click="handleAdd"
                        >确定</el-button
                      >
                    </div>
                  </div>
                  <el-button
                    type="primary"
                    class="general_bgc general_border cfff"
                    slot="reference"
                    >新增</el-button
                  >
                </el-popover>
              </el-form-item>
            </el-col>
          </el-form>
        </div>
        <el-table :data="list" style="width: 100%">
          <el-table-column prop="label" label="配置" width="240">
          </el-table-column>
          <el-table-column prop="isDefault" label="类型" width="240">
            <template slot-scope="scope">
              {{ scope.row.isDefault ? "系统默认" : "自定义" }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <template v-if="scope.row.isDefault">
                <el-button
                  type="text"
                  class="disib red"
                  size="mini"
                  style="margin-left: 10px"
                  @click="handleHide(scope.row)"
                  v-if="!scope.row.disable"
                  >停用</el-button
                >
                <el-button
                  type="text"
                  class="disib"
                  size="mini"
                  style="margin-left: 10px"
                  v-else
                  @click="handleHide(scope.row)"
                  >启用</el-button
                >
              </template>

              <el-button
                v-else
                type="text"
                class="disib red"
                size="mini"
                style="margin-left: 10px"
                @click="handleDel(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="df" style="justify-content: flex-end">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page.sync="currentPage"
            @current-change="pageChange"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../util/extra-api";
import { local } from "@/util/util";

export default {
  watch: {
    "query.page"(newValue, oldValue) {
      console.log(newValue, oldValue);
      this.currentPage = newValue;
    },
  },
  data() {
    return {
      tableData: [
        {
          name: "种养单位配置",
          type: 0,
          value: "7",
        },
        {
          name: "追溯环节配置",
          type: 1,
          value: "8",
        },
        {
          name: "奖励原因配置",
          type: 2,
          value: "1",
        },
        {
          name: "扣罚原因配置",
          type: 3,
          value: "1",
        },{
          name: "受托记录类型配置",
          type: 4,
          value: "2",
        },{
          name: "委托类型配置",
          type: 5,
          value: "2",
        }
      ],
      query: {
        keyword: "",
        page: 1,
        limit: 10,
        companyId: null,
        type: null,
      },
      formData: {
        companyId: null,
        label: "",
        type: null,
        value: null,
      },
      title: "",
      list: [],
      total: 0,
      dialogVisible: false,
      showAdd: false,
      rules: {
        label: [
          { required: true, message: "请输入自定义名称", trigger: "blur" },
        ],
      },
      currentPage: 1,
    };
  },
  mounted() {
    this.query.companyId = local.get("company_id");
    this.formData.companyId = local.get("company_id");
  },
  methods: {
    search() {
      this.query.page = 1;
      this.getList();
    },
    handleAdd() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          api.post("/v1/pc/select/add", this.formData).then((res) => {
            if (res.code == 200) {
              this.formData.label = "";
              this.showAdd = false;
              this.query.page = Math.floor(this.total / 10) + 1;
              this.getList();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {},
    pageChange(page) {
      this.query.page = page;
      this.getList();
    },
    handleHide(row) {
      api
        .post("/v1/pc/select/disable", {
          disable: !row.disable,
          id: row.id,
          companyId: local.get("company_id"),
        })
        .then((res) => {
          if (res.code == 200) {
            this.getList();
          }
        });
    },
    handleDel(row) {
      api
        .post("/v1/pc/select/delete", {
          id: row.id,
          companyId: local.get("company_id"),
        })
        .then((res) => {
          if (res.code == 200) {
            if (this.list.length === 1 && this.query.page > 1) {
              this.$set(this.query, "page", this.query.page - 1);
            }
            this.getList();
          }
        });
    },
    getList() {
      api.get("/v1/pc/select/list", this.query).then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          this.total = res.total;
          if (!this.dialogVisible) {
            this.dialogVisible = true;
          }
        }
      });
    },
    handleClick(row) {
      this.title = row.name;
      this.query.type = row.type;
      this.formData.type = row.type;
      this.formData.value = row.value;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>